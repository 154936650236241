import React from "react";
import { useTranslation } from "next-i18next";
import { StyledContainer } from "styles/basic.styled";
import SumsubWebSdk from "@sumsub/websdk-react";
import { TitleBox } from "components/molecules/title-box/title-box.component";
import {
    StyledSumSubModals,
    StyledSumSubBox,
} from "./sumsub-modals.styled";

export interface IProps {
    closeModal: () => void;
    sumsub: string;
}

export const SumSubModals: React.FC<IProps> = ({ closeModal, sumsub }) => {
    const [__] = useTranslation();

    return (
        <StyledSumSubModals
            closeModal={closeModal}
            isOverlay={false}
        >
            <TitleBox
                type="button"
                title={__("components.profile-verification")}
                onBack={closeModal}
            />
            <StyledContainer>
                <StyledSumSubBox>
                    <SumsubWebSdk
                        accessToken={sumsub}
                        expirationHandler={() => Promise.resolve("test")}
                        config={{
                            lang: "ru-RU",
                            email: "",
                            phone: "",
                        }}
                        onMessage={(data, payload) => console.log("onMessage", data, payload)}
                        onError={(data) => console.log("onError", data)}
                    />
                </StyledSumSubBox>
            </StyledContainer>
        </StyledSumSubModals>
    );
};
