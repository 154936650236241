import React from "react";
import { ButtonTypes, ButtonIcon, ButtonSize } from "./button-link.types";
import { IconTypes } from "../icon/icon.types";
import { Typography } from "../typography/typography.component";
import { TypographyTypes } from "../typography/typography.types";
import {
    StyledButtonIcon,
    StyledIcon,
    StyledText,
    StyledButtonPrimary,
    StyledIconPrimary,
    StyledButtonWhiteBlack,
    StyledButtonSecondary,
    StyledSecondaryIcon,
    StyledButtonDelete,
    StyledButtonTransfer,
    StyledArrowIcon,
    StyledButtonBlack,
} from "./button-link.styled";

export interface IProps extends React.ButtonHTMLAttributes<HTMLButtonElement>{
    variant: ButtonTypes;
    text?: JSX.Element | string;
    className?: string;
    icon?: ButtonIcon | IconTypes;
    iconPrimary?: IconTypes;
    size?: ButtonSize;
    arrow?: boolean;
}

export const ButtonLink: React.FC<IProps> = ({
    variant,
    text,
    className,
    icon,
    iconPrimary,
    size = ButtonSize.DEFAULT,
    arrow,
    ...rest
}) => {
    let component!: JSX.Element;
    const variantSize = () => {
        switch(size) {
            case ButtonSize.SMALL: return TypographyTypes.BASE;
            case ButtonSize.MINI: return TypographyTypes.SMALL;
            default: return TypographyTypes.BUTTON;
        }
    };
    switch (variant) {
        case ButtonTypes.SOCIAL:
            component = (
                <StyledButtonIcon
                    className={className}
                    {...rest}
                >
                    <StyledIcon>
                        <use xlinkHref={`/svg/icons-social.svg#${icon}`} />
                    </StyledIcon>
                    {text && <StyledText variant={TypographyTypes.BUTTON}>{text}</StyledText>}
                </StyledButtonIcon>
            );
            break;
        case ButtonTypes.PRIMARY:
            component = (
                <StyledButtonPrimary
                    $size={size}
                    className={className}
                    {...rest}
                >
                    {iconPrimary && <StyledIconPrimary icon={iconPrimary} />}
                    <Typography
                        variant={size ===  ButtonSize.SMALL ? TypographyTypes.DEFAULT : TypographyTypes.BUTTON}
                    >
                        {text}
                    </Typography>
                </StyledButtonPrimary>
            );
            break;
        case ButtonTypes.WHIT_BLACK:
            component = (
                <StyledButtonWhiteBlack
                    $size={size}
                    className={className}
                    {...rest}
                >
                    <Typography
                        variant={size ===  ButtonSize.SMALL ? TypographyTypes.DEFAULT : TypographyTypes.BUTTON}
                    >
                        {text}
                    </Typography>
                </StyledButtonWhiteBlack>
            );
            break;
        case ButtonTypes.BLACK:
            component = (
                <StyledButtonBlack
                    $size={size}
                    className={className}
                    {...rest}
                >
                    <Typography variant={variantSize()}>{text}</Typography>
                </StyledButtonBlack>
            );
            break;
        case ButtonTypes.DELETE:
            component = (
                <StyledButtonDelete
                    className={className}
                    {...rest}
                >
                    <Typography variant={TypographyTypes.BUTTON}>{text}</Typography>
                </StyledButtonDelete>
            );
            break;
        case ButtonTypes.TRANSFER:
            component = (
                <StyledButtonTransfer
                    className={className}
                    {...rest}
                >
                    <Typography variant={TypographyTypes.SMALL}>{text}</Typography>
                </StyledButtonTransfer>
            );
            break;
        case ButtonTypes.SECONDARY:
            component = (
                <StyledButtonSecondary
                    $size={size}
                    className={className}
                    {...rest}
                >
                    {icon &&
                        <StyledSecondaryIcon>
                            <use xlinkHref={`/svg/icons-sprite.svg#${icon}`} />
                        </StyledSecondaryIcon>
                    }
                    <Typography variant={variantSize()}>
                        {text}
                    </Typography>
                    {arrow && <StyledArrowIcon icon={IconTypes.ARROW} />}
                </StyledButtonSecondary>
            );
            break;
    }
    return component;
};
