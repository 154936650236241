import styled, { css } from "styled-components";
import { ButtonSize } from "./button-link.types";
import { Icon } from "../icon/icon.component";
import { Typography } from "../typography/typography.component";
import { spacing } from "helpers/conversion-style";
import { media } from "lib/styled-components/variables";

const defaultButtonCSS = css`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: none;
    transition: 0.3s ease-in-out;
    &:disabled {
        pointer-events: none;
    }
`;

export const StyledButtonIcon = styled.button`
    ${defaultButtonCSS};
    border: 1px solid ${({theme}) => theme.gray?.[200]};
    background-color: ${({theme}) => theme.gray?.[100]};
    border-radius: ${spacing(8)};
    width: 92px;
    height: 60px;
    box-shadow: 0 4px 8px 0 #FFFFFF;
`;

export const StyledIcon = styled.svg`
    max-width: 36px;
    max-height: 36px;
`;

export const StyledText = styled(Typography)`
    display: none;
    ${media.sm`
        display: block;
    `};
`;

export const StyledButtonPrimary = styled.button<{$size: ButtonSize}>`
    ${defaultButtonCSS};
    border-radius: 32px;
    padding: ${spacing(16)};
    min-height: 56px;
    background-color: ${({ theme}) => theme.green?.[800]};
    color: ${({ theme}) => theme.green?.[1400]};
    width: 100%;
    ${({ $size }) => {
        switch ($size) {
            case ButtonSize.SMALL:
                return css`
                    border-radius: 32px;
                    padding: 12px;
                    width: auto;
                    display: flex;
                    align-items: center;
                    gap: 6px;
                    min-height: 40px;
                `;
        }
    }};
    &:hover {
        background-color: ${({ theme}) => theme.green?.[900]};
    }
    &:active {
        background-color: ${({ theme}) => theme.green?.[1000]};
    }
    &:focus {
        background-color: ${({ theme}) => theme.green?.[1000]};
    }
    &:disabled {
        background-color: ${({ theme}) => theme.green?.[400]};
        color: ${({ theme}) => theme.gray?.[1000]};
    }
`;

export const StyledIconPrimary = styled(Icon)`
    width: 16px;
    height: 16px;
    min-width: 16px;
    stroke: ${({ theme }) => theme.text?.default};
`;

export const StyledButtonDelete = styled.button`
    ${defaultButtonCSS};
    border-radius: 32px;
    padding: ${spacing(16)};
    background-color: ${({ theme}) => theme.red?.[800]};
    color: ${({ theme}) => theme.bg?.default};
    width: 100%;
    &:hover {
        background-color: ${({ theme}) => theme.red?.[900]};
    }
    &:active {
        background-color: ${({ theme}) => theme.red?.[1000]};
    }
    &:focus {
        background-color: ${({ theme}) => theme.red?.[1000]};
    }
    &:disabled {
        background-color: ${({ theme}) => theme.red?.[400]};
    }
`;

export const StyledButtonTransfer = styled.button`
    ${defaultButtonCSS};
    border-radius: 20px;
    padding: ${spacing(8)} ${spacing(12)};
    background-color: ${({ theme}) => theme.text?.default};
    color: ${({ theme}) => theme.bg?.default};
    &:hover {
        background-color: ${({ theme}) => theme.gray[1500]};
    }
    &:active {
        background-color: ${({ theme}) => theme.gray?.[1400]};
    }
    &:focus {
        background-color: ${({ theme}) => theme.gray?.[1400]};
    }
    &:disabled {
        background-color: ${({ theme}) => theme.gray?.[1000]};
        color: ${({ theme}) => theme.bg?.default};
    }
`;

export const StyledButtonBlack = styled.button<{$size: ButtonSize}>`
    ${({ $size }) => {
        switch ($size) {
            case ButtonSize.DEFAULT:
                return css`
                    border-radius: 32px;
                    padding: ${spacing(16)};
                    width: 100%;
                `;
            case ButtonSize.SMALL:
                return css`
                    border-radius: 24px;
                    padding: ${spacing(16)};
                    width: auto;
                `;
            case ButtonSize.MINI:
                return css`
                    border-radius: 20px;
                    padding: ${spacing(8)} ${spacing(12)};
                    width: auto;
                `;
            case ButtonSize.BIG:
                return css`
                    border-radius: 32px;
                    padding: ${spacing(20)} ${spacing(26)};
                    width: 100%;
                `;
        }
    }};
    ${defaultButtonCSS};
    background-color: ${({ theme}) => theme.text?.default};
    color: ${({ theme}) => theme.bg?.default};
    &:hover {
        background-color: ${({ theme}) => theme.gray[1500]};
    }
    &:active {
        background-color: ${({ theme}) => theme.gray?.[1400]};
    }
    &:focus {
        background-color: ${({ theme}) => theme.gray?.[1400]};
    }
    &:disabled {
        background-color: ${({ theme}) => theme.gray?.[1000]};
        color: ${({ theme}) => theme.bg?.default};
    }
`;

export const StyledButtonSecondary = styled.button<{$size: ButtonSize}>`
    ${({ $size }) => {
        switch ($size) {
            case ButtonSize.DEFAULT:
                return css`
                    border-radius: 32px;
                    padding: ${spacing(16)};
                    width: 100%;
                `;
            case ButtonSize.SMALL:
                return css`
                    border-radius: 24px;
                    padding: ${spacing(16)};
                    width: auto;
                `;
            case ButtonSize.MINI:
                return css`
                    border-radius: 20px;
                    padding: ${spacing(8)} ${spacing(12)};
                    width: auto;
                `;
            case ButtonSize.BIG:
                return css`
                    border-radius: 32px;
                    padding: ${spacing(20)} ${spacing(26)};
                    width: 100%;
                `;
        }
    }};
    ${defaultButtonCSS};
    background-color: ${({ theme}) => theme.gray?.[100]};
    color: ${({ theme}) => theme.text?.default};
    gap: 8px;
    &:hover {
        background-color: ${({ theme}) => theme.gray?.[200]};
    }
    &:active {
        background-color: ${({ theme}) => theme.gray?.[300]};
    }
    &:focus {
        background-color: ${({ theme}) => theme.gray?.[300]};
    }
    &:disabled {
        background-color: ${({ theme}) => theme.gray?.[200]};
        color: ${({ theme}) => theme.text.muted};
    }
`;

export const StyledButtonWhiteBlack = styled.button<{$size: ButtonSize}>`
    ${defaultButtonCSS};
    border-radius: 32px;
    padding: ${spacing(16)};
    min-height: 56px;
    background-color: inherit;
    color: ${({ theme}) => theme.text.default};
    border: 1px solid ${({ theme}) => theme.components.accent__dark_border};
    width: 100%;
    ${({ $size }) => {
        switch ($size) {
            case ButtonSize.SMALL:
                return css`
                    border-radius: 32px;
                    padding: 12px;
                    width: auto;
                    min-height: 40px;
                `;
        }
    }};
`;

export const StyledSecondaryIcon = styled.svg`
    width: 24px;
    min-width: 24px;
    height: 24px;
    stroke: ${({ theme}) => theme.text?.default};
`;

export const StyledArrowIcon = styled(Icon)`
    width: 24px;
    min-width: 24px;
    height: 24px;
    stroke: ${({ theme}) => theme.text?.muted};
`;
