export enum ButtonTypes {
    PRIMARY = "primary",
    SOCIAL = "social",
    SECONDARY = "secondary",
    DELETE = "delete",
    TRANSFER = "transfer",
    BLACK = "black",
    WHIT_BLACK = "white-black",
}

export enum ButtonIcon {
    FACEBOOK = "facebook",
    GOOGLE = "google",
    UPDATE = "update",
    CALENDAR = "calendar",
};

export enum ButtonSize {
    DEFAULT = "default",
    SMALL = "small",
    MINI = "mini",
    BIG = "big",
};
